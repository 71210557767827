import React, { type FC } from 'react'

import { Icon, Markdown, Text } from 'mf-ui-components'

import Accordion from '../blocks/mfblocks/Accordion'
import { l10nCopy } from '../../utils/copy/l10nCopy'
import { CloseButton, ContentWrapper, ModalWrapper } from './Modal.styles'

type Props = { hide: () => void }

export const Modal: FC<Props> = ({ hide }) => (
  <ModalWrapper>
    <CloseButton onClick={hide}>
      <Icon
        type="icon-plus"
        size={16}
        style={{ transform: 'rotate(45deg) translateY(2px)' }}
      />
    </CloseButton>

    <ContentWrapper>
      <Markdown markdown={l10nCopy('globalDisclaimer.intro')} />

      <div>
        {[
          'investmentRisk',
          'typesOfInvestmentRisk',
          'riskVariance',
          'pastAndFuturePerformance',
          'managingRisk',
          'taxAndLegal',
          'yourRole',
        ].map((section) => (
          <Accordion
            key={section}
            variant="fullWidth"
            childBlock
            text={l10nCopy(`globalDisclaimer.disclaimers.${section}.title`)}
          >
            <Text style={{ color: 'black' }}>
              <Markdown
                markdown={l10nCopy(
                  `globalDisclaimer.disclaimers.${section}.text`
                )}
              />
            </Text>
          </Accordion>
        ))}
      </div>
    </ContentWrapper>
  </ModalWrapper>
)
