/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC } from 'react'

import { Theme } from 'mf-ui-components'

import { Header } from '../Header'
import { Footer } from '../Footer'
import { TopBanner } from '../TopBanner'
import { query } from './query'
import { Main } from './Layout.styles'
import { useStaticData } from './useStaticData'
import { GlobalDisclaimer } from '../GlobalDisclaimer/GlobalDisclaimer'

type Props = { themeId: Theme['id'] }

const Layout: FC<Props> = ({ themeId, children }) => {
  // Map the graphQL data to the same point for the footer.
  const mapToFooterInfo = (menu: { node: any }[]) => menu[0].node

  const {
    wpgraphql: {
      primary: { edges: menuPrimary },
      footer1: { edges: menuFooter1 },
      footer2: { edges: menuFooter2 },
      footer3: { edges: menuFooter3 },
      footer4: { edges: menuFooter4 },
      legal: { edges: menuLegal },
      globalOptions: {
        footerContent: { content },
        topBanner,
      },
    },
  } = useStaticData()

  return (
    <>
      <TopBanner {...topBanner} />
      <Header
        menuVersion={themeId === 'mf-2023-light' ? 2 : 1}
        menu={menuPrimary}
      />
      <Main>{children}</Main>
      <Footer
        menu={[
          mapToFooterInfo(menuFooter1),
          mapToFooterInfo(menuFooter2),
          mapToFooterInfo(menuFooter3),
          mapToFooterInfo(menuFooter4),
        ]}
        legal={mapToFooterInfo(menuLegal)}
        content={content}
      />
      <GlobalDisclaimer />
    </>
  )
}

export default Layout
export { query }
