import styled, { keyframes } from 'styled-components'

import { fromBreakpoint } from '../../utils/style/breakpoint'
import { spacing } from '../../utils/ui/spacing'

const ModalAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(128px) perspective(100px) scale(0.95) rotateX(-2deg);
  }

  to {
    opacity: 1;
    transform: translateY(0) perspective(100px) scale(1) rotateX(0deg);
  }
`

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 200;
  background-color: ${({ theme }) => theme.tokens.space.secondary.regular};
  border: 1px solid ${({ theme }) => theme.tokens.space.primary.regular};
  padding: ${spacing('md')};
  overflow: hidden;
  border-radius: 8px;
  box-shadow:
    0px 4px 16px rgba(0, 0, 0, 0.08),
    0px 2px 4px rgba(0, 0, 0, 0.08);
  animation: ${ModalAnimation} 800ms cubic-bezier(0.175, 0.885, 0.32, 1.175);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 84vw;
  height: 74vh;

  a[href='#'] {
    font-family: sans-serif;
    font-size: 12pt !important;
  }

  div:has(a[href='#']) {
    padding: ${spacing('xs')} 0;
    margin-bottom: 0;
  }

  ${fromBreakpoint('lg')} {
    padding: ${spacing('xl')};
    bottom: 80px;
    right: 32px;
    left: unset;
    top: unset;
    margin: 0;
    width: 440px;
    height: fit-content;

    a[href='#'] {
      font-size: 14pt !important;
    }

    div:has(a[href='#']) {
      padding: ${spacing('sm')} 0;
    }
  }
`

export const ContentWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spacing('xs')};

  ${fromBreakpoint('lg')} {
    max-height: 56vh;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 8px;
  background-color: #00000008;
  cursor: pointer;
  border: none;
  width: 32px;
  height: 32px;
  z-index: 2220;

  :hover {
    background-color: #00000010;
  }
`
