import React, { useState, type FC } from 'react'

import { Markdown } from 'mf-ui-components'

import { Modal } from './Modal'
import { Banner } from './styles'
import { l10nCopy } from '../../utils/copy/l10nCopy'

export const GlobalDisclaimer: FC = () => {
  const [visible, setVisible] = useState<boolean>(false)

  return (
    <>
      <Banner id="global-disclaimer" onClick={() => setVisible(true)}>
        <Markdown markdown={l10nCopy('globalDisclaimer.bannerText')} />
      </Banner>

      {visible && <Modal hide={() => setVisible(false)} />}
    </>
  )
}
