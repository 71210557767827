import styled from 'styled-components'

import { spacing } from '../../utils/ui/spacing'

export const Banner = styled.aside`
  background-color: #75596f;
  cursor: help;
  padding: ${spacing('xs')};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 100;
  text-align: center;

  p {
    color: ${({ theme }) => theme.tokens.text.paragraph.reversed};
    margin: 0;
    line-height: 1.4;
  }
`
